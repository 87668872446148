// router.js

import { createRouter, createWebHistory } from 'vue-router';
import Login from '@/views/Login.vue';
import CustomerService from '@/views/CustomerService.vue';
import { isTokenValid } from '@/services/auth';

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/',
    name: 'CustomerService',
    component: CustomerService,
    meta: { requiresAuth: true }, // 添加需要认证的标识
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// 添加全局路由守卫，检查 token
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!isTokenValid()) {
      next('/login'); // token 无效，重定向到登录页面
    } else {
      next(); // 继续导航
    }
  } else {
    next(); // 无需验证
  }
});

export default router;
