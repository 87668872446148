<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
};
document.title = 'MCN客服系统';
</script>

<style>
/* 全局样式 */
</style>
