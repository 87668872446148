// auth.js

export function saveToken(token) {
    const tokenExpiry = new Date().getTime() + 30 * 24 * 60 * 60 * 1000; // 设置一个月有效期
    localStorage.setItem('token', token);
    localStorage.setItem('tokenExpiry', tokenExpiry);
  }
  
  export function isTokenValid() {
    const token = localStorage.getItem('token');
    const tokenExpiry = localStorage.getItem('tokenExpiry');
    const now = new Date().getTime();
  
    // 检查 token 是否存在且是否过期
    return token && tokenExpiry && now < tokenExpiry;
  }
  
  export function logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('tokenExpiry');
    localStorage.removeItem('mcn_id');
    localStorage.removeItem('username');
    localStorage.removeItem('avatar');
    localStorage.removeItem('supportId');
  }
  