<template>
  <div class="login-container">
    <div class="login-box">
      <h2>手机号码登录</h2>
      <form @submit.prevent="login">
        <div class="input-group">
          <label for="phone">手机号</label>
          <input
            type="text"
            id="phone"
            v-model="phoneNumber"
            placeholder="请输入手机号"
            required
          />
        </div>
        <div class="input-group code-group">
          <label for="code">验证码</label>
          <div class="code-input-wrapper">
            <input
              type="text"
              id="code"
              v-model="verificationCode"
              placeholder="请输入验证码"
              required
            />
            <CountdownTimer @send="sendSmsCode" />
          </div>
        </div>
        <div class="input-group">
          <button type="submit">登录</button>
        </div>
        <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
      </form>
    </div>
  </div>
</template>

<script>
import CountdownTimer from '@/components/CountdownTimer.vue';
import { saveToken } from '@/services/auth'; // 引入保存 token 的方法
import { API_BASE_URL } from '@/services/config';
import axios from 'axios';

export default {
  name: 'UserLogin',
  components: {
    CountdownTimer,
  },
  data() {
    return {
      phoneNumber: '',
      verificationCode: '',
      userIds: [], // 存储从后端获取的用户ID
      errorMessage: '',
    };
  },
  methods: {
    validatePhone() {
      // 简单的手机号格式验证
      const phonePattern = /^1[3-9]\d{9}$/;
      return phonePattern.test(this.phoneNumber);
    },
    async sendSmsCode() {
      if (!this.validatePhone()) {
        this.errorMessage = '手机号格式不正确';
        return;
      }
      this.errorMessage = ''; // 清除错误信息

      try {
        // 调用后端接口发送验证码
        const response = await axios.get(`${API_BASE_URL}/api/customchat/phone-login`, { params: { phone: this.phoneNumber } });
        this.userIds = response.data; // 获取并存储用户ID
      } catch (error) {
        this.errorMessage = error.response.data.error || '验证码发送失败';
      }
    },
    async login() {
      if (!this.validatePhone()) {
        this.errorMessage = '手机号格式不正确';
        return;
      }

      if (!this.userIds.length) {
        this.errorMessage = '请先获取验证码';
        return;
      }

      try {
        // 调用后端接口验证验证码
        const response = await axios.get(`${API_BASE_URL}/api/customchat/phone-verify`, {
          params: {
            id: this.userIds[0], // 使用第一个用户ID
            phone: this.phoneNumber,
            code: this.verificationCode,
          },
        });
        const { token, mcn_id, username, avatar,supportId } = response.data; // 获取返回数据中的 token、mcn_id、username 和 avatar
        saveToken(token); // 保存 token
        localStorage.setItem('mcn_id', mcn_id);     // 存储 mcn_id
        localStorage.setItem('username', username); // 存储用户名
        localStorage.setItem('avatar', avatar);     // 存储头像
        localStorage.setItem('supportId',supportId)
        this.$router.push('/'); // 登录成功后跳转到客服页面
      } catch (error) {
        this.errorMessage = error.response.data.error || '验证码错误，请重试';
      }
    },
  },
};
</script>

<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
}

.login-box {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 400px;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

.input-group {
  margin-bottom: 15px;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
}

.input-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.input-group button {
  width: 100%;
  padding: 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.code-group {
  display: flex;
  flex-direction: column;
}

.code-input-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.code-input-wrapper input {
  width: 55%; /* 输入框宽度 */
}

.code-input-wrapper button {
  width: 40%; /* 发送验证码按钮宽度 */
  margin-left: 5%;
  font-size: 14px;
}

.error {
  color: red;
  text-align: center;
  margin-top: 10px;
}
</style>
