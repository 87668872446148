<template>
  <div class="customer-service">
    <!-- 右上角用户信息和下拉菜单 -->
    <div class="header">
      <div class="user-info">
        <img :src="avatar" alt="User Avatar" class="user-avatar" />
        <!-- 显示用户头像 -->
        <span @click="toggleDropdown">{{ username }}（{{ mcn_id }}）</span>
        <!-- 显示用户名 -->
        <div v-if="showDropdown" class="dropdown">
          <div @click="goToSettings">设置</div>
          <div @click="logout">退出登录</div>
        </div>
      </div>
    </div>

    <!-- 主体内容 -->
    <div class="main-container">
      <!-- 左侧：待接入与已接入客户列表 -->
      <div class="left-panel">
        <div class="section pending-clients-section">
          <h3>待接入 {{ pendingClients.length }}</h3>
          <div class="pending-clients-wrapper">
            <div v-for="client in pendingClients" :key="client.connection_id" class="client pending-client"
              @click="acceptClient(client)">
              <img :src="client.user_avatar" alt="Client Avatar" />
              <span>{{ client.user_id }}</span>
            </div>
          </div>
        </div>

        <div class="section accepted-clients-section">
          <h3>已接入</h3>
          <transition-group name="list" tag="div" class="accepted-clients-wrapper">
            <div v-for="client in acceptedClients" :key="client.connection_id" class="client accepted-client"
              @click="selectClient(client)">
              <img :src="client.user_avatar" alt="Client Avatar" />
              <span>{{ client.user_id }}</span>
              <!-- 如果有未读消息，显示红点 -->
              <span v-if="client.unreadMessages" class="unread">●</span>
            </div>
          </transition-group>
        </div>
      </div>

      <!-- 中间：聊天窗口 -->
      <div class="chat-panel" v-if="activeClient">
        <div class="chat-header">
          <img :src="activeClient.user_avatar" alt="Client Avatar" class="header-avatar" />
          <span class="chat-title">{{ activeClient.user_name }}</span>
        </div>

        <div class="chat-messages">
          <!-- 渲染已读消息 -->
          <div v-for="(message, index) in readMessages" :key="index"
            :class="['message', message.sender_type === 'user' ? 'received' : 'sent']">
            <div class="message-time">{{ message.create_time }}</div>
            <div class="message-content">
              <p>{{ message.message_content }}</p>
              <span v-if="message.failed" class="error-message">发送失败</span>
            </div>
          </div>

          <!-- 显示「以上是历史聊天记录」的分隔线 -->
          <div class="divider" v-if="readMessages.length > 0">以上是历史聊天记录</div>

          <!-- 渲染未读消息 -->
          <div v-for="(message, index) in unreadMessages" :key="index"
            :class="['message', message.sender_type === 'user' ? 'received' : 'sent']">
            <div class="message-time">{{ message.create_time }}</div>
            <div class="message-content">
              <p>{{ message.message_content }}</p>
              <span v-if="message.failed" class="error-message">发送失败</span>
            </div>
          </div>
        </div>

        <div class="chat-footer">
          <input type="text" class="chat-input" v-model="newMessage" placeholder="请输入内容..."
            @keydown.enter.prevent="handleSendMessage" />
          <button class="send-btn" @click="handleSendMessage">发送</button>
        </div>
      </div>

      <!-- 右侧：客户信息面板 -->
      <div class="info-panel" v-if="activeClient">
        <div class="info-header">
          <span>基础信息</span>
        </div>
        <div class="client-info">
          <!-- <div class="client-field">会话来源: {{ activeClient.source || "未知" }}</div>
          <div class="client-field">交易情况: {{ activeClient.transaction || "暂无" }}</div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as signalR from "@microsoft/signalr";
import { isTokenValid, logout } from "@/services/auth";
import { API_BASE_URL } from '@/services/config';
export default {
  name: "CustomerService",
  data() {
    return {
      username: "当前用户",
      showDropdown: false,
      pendingClients: [],
      acceptedClients: [],
      activeClient: null,
      newMessage: "",
      mcn_id: null,
      avatar: "",
      connection: null, // SignalR 连接
    };
  },
  computed: {
    readMessages() {
      return this.activeClient && this.activeClient.messages
        ? this.activeClient.messages.filter(message => message.is_read).sort((a, b) => new Date(a.create_time) - new Date(b.create_time))
        : [];
    },
    unreadMessages() {
      return this.activeClient && this.activeClient.messages
        ? this.activeClient.messages.filter(message => !message.is_read).sort((a, b) => new Date(a.create_time) - new Date(b.create_time))
        : [];
    }
  },
  mounted() {
    const token = localStorage.getItem("token");
    if (!isTokenValid(token)) {
      this.$router.push("/");
    } else {
      this.mcn_id = localStorage.getItem("mcn_id");
      this.username = localStorage.getItem("username");
      this.avatar = localStorage.getItem("avatar");
      const supportId = localStorage.getItem("supportId");

      this.initializeSignalRConnection(token, supportId);
      if (Notification.permission !== "granted") {
        Notification.requestPermission();
      }
    }
  },
  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    goToSettings() {
      alert("跳转到设置页面");
    },
    logout() {
      logout();
      this.$router.push("/login");
    },
    initializeSignalRConnection(token, supportId) {
      const mcnId = this.mcn_id;

      this.connection = new signalR.HubConnectionBuilder()
        .withUrl(`${API_BASE_URL}/chatHub?mcn_id=${mcnId}&supportId=${supportId}`, {
          accessTokenFactory: () => token
        })
        .withAutomaticReconnect()
        .build();

      this.connection.start()
        .then(() => {
          console.log("SignalR connected.");
          this.setupSignalREventHandlers();
          this.fetchPendingClients();
          this.fetchAcceptedClients();
        })
        .catch(err => console.error("SignalR connection error: ", err));
    },
    setupSignalREventHandlers() {
      // 接收消息
      this.connection.on("ReceiveMessage", (connectionId, messageContent, senderType) => {
        const client = this.acceptedClients.find(c => c.connection_id === connectionId);
        if (client) {
          const message = {
            message_content: messageContent,
            sender_type: senderType,
            create_time: new Date().toLocaleString('zh-CN', { timeZone: 'Asia/Shanghai' }),
            is_read: false
          };
          client.messages.push(message);

          client.lastMessageTime = message.create_time; // 更新 lastMessageTime

          if (this.activeClient && this.activeClient.connection_id === connectionId) {
            // 如果是当前聊天窗口，标记为已读
            this.markMessagesRead(connectionId);
            this.scrollToBottom();
          } else {
            // 如果不是当前聊天窗口，显示未读红点
            client.unreadMessages = true;
            this.notify("您有新的消息");
          }

          this.sortAcceptedClients(); // 对 acceptedClients 进行排序
        }
      });

      // 新的待接入客户
      this.connection.on("NewPendingClient", (client) => {
        this.pendingClients.push(client);
        console.log("New pending client:", client);
        this.notify("有新的待接入客户");
      });

      // 客户被接入
      this.connection.on("ClientAccepted", (clientConnectionId) => {
        console.log("Client accepted:", clientConnectionId);
      });

      // 客户被其他客服接入
      this.connection.on("ClientAcceptedByOther", (clientConnectionId) => {
        this.pendingClients = this.pendingClients.filter(c => c.connection_id !== clientConnectionId);
      });
    },
    async fetchPendingClients() {
      try {
        const clients = await this.connection.invoke("GetPendingClients");
        this.pendingClients = clients;
      } catch (err) {
        console.error("Error fetching pending clients:", err);
      }
    },
    async fetchAcceptedClients() {
      try {
        const clients = await this.connection.invoke("GetAcceptedClients");
        clients.forEach(client => {
          // 假设服务器返回的 messages 已经按照时间排序
          if (client.messages && client.messages.length > 0) {
            const lastMessage = client.messages[client.messages.length - 1];
            client.lastMessageTime = lastMessage.create_time;
          } else {
            client.lastMessageTime = new Date().toLocaleString('zh-CN', { timeZone: 'Asia/Shanghai' });
          }

          const hasUnreadMessages = client.messages.some(message => !message.is_read);
          client.unreadMessages = hasUnreadMessages;
        });
        this.acceptedClients = clients;
        this.sortAcceptedClients(); // 对 acceptedClients 进行排序
      } catch (err) {
        console.error("Error fetching accepted clients:", err);
      }
    },
    async acceptClient(client) {
      try {
        // 接入客户
        await this.connection.invoke("AcceptClient", client.connection_id);

        const clientIndex = this.pendingClients.findIndex(c => c.connection_id === client.connection_id);
        if (clientIndex !== -1) {
          const acceptedClient = this.pendingClients.splice(clientIndex, 1)[0];
          acceptedClient.unreadMessages = false;
          acceptedClient.messages = []; // 初始化 messages 为空数组
          acceptedClient.lastMessageTime = new Date().toLocaleString('zh-CN', { timeZone: 'Asia/Shanghai' }); // 初始化 lastMessageTime 为当前时间
          this.acceptedClients.unshift(acceptedClient);
          this.sortAcceptedClients(); // 对 acceptedClients 进行排序

          // 立即加载消息并显示到聊天窗口
          await this.getClientMessages(acceptedClient.connection_id);
          this.selectClient(acceptedClient);
        }
      } catch (err) {
        console.error("Error accepting client:", err);
      }
    },
    async selectClient(client) {
      client.unreadMessages = false; // 标记该客户端没有未读消息
      this.activeClient = client;    // 将该客户端设为当前活动客户端

      if (!client.messages || client.messages.length === 0) {
        // 如果没有加载过消息，获取消息
        await this.getClientMessages(client.connection_id);
      }

      // 如果有未读消息，调用后端标记为已读
      if (client.messages.some(msg => !msg.is_read)) {
        try {
          await this.connection.invoke("MarkMessagesRead", client.connection_id);
        } catch (err) {
          console.error("Error marking messages read:", err);
        }
      }

      this.scrollToBottom(); // 滚动到底部
    },
    async getClientMessages(connectionId) {
      try {
        const messages = await this.connection.invoke("GetClientMessages", connectionId);
        const client = this.acceptedClients.find(c => c.connection_id === connectionId);
        if (client) {
          client.messages = messages;

          if (messages.length > 0) {
            // 更新 lastMessageTime 为最后一条消息的时间
            const lastMessage = messages[messages.length - 1];
            client.lastMessageTime = lastMessage.create_time;
          } else {
            client.lastMessageTime = new Date().toLocaleString('zh-CN', { timeZone: 'Asia/Shanghai' });
          }

          this.sortAcceptedClients(); // 对 acceptedClients 进行排序
        }
      } catch (err) {
        console.error("Error fetching client messages:", err);
      }
    },
    async handleSendMessage() {
      if (this.newMessage.trim() !== "") {
        const connectionId = this.activeClient.connection_id;
        const messageContent = this.newMessage;
        const message = {
          message_content: messageContent,
          sender_type: "support",
          create_time: new Date().toLocaleString('zh-CN', { timeZone: 'Asia/Shanghai' }),
          is_read: false,
          failed: false,
          errorMessage: ""
        };


        try {
          // 调用后端发送消息
          const result = await this.connection.invoke("SendMessageToClient", connectionId, messageContent, localStorage.getItem("supportId"));

          if (!result.success) {
            // 如果发送失败，则将消息标记为失败
            message.failed = true;
            message.errorMessage = result.errorMessage || "Failed to send the message";
          }
        } catch (err) {
          // 如果发生异常，标记为失败
          message.failed = true;
          message.errorMessage = "Error sending the message";
          console.log(err);
        }
        // 先将消息添加到 UI，之后再处理是否发送成功
        this.activeClient.messages.push(message);
        this.activeClient.lastMessageTime = message.create_time;
        this.newMessage = ""; // 清空输入框
        this.scrollToBottom();
        
        // 确保 UI 显示发送结果
        this.sortAcceptedClients();
      }
    },
    async markMessagesRead(connectionId) {
      try {
        await this.connection.invoke("MarkMessagesRead", connectionId);
      } catch (err) {
        console.error("Error marking messages read:", err);
      }
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const chatMessages = this.$el.querySelector(".chat-messages");
        if (chatMessages) {
          chatMessages.scrollTop = chatMessages.scrollHeight;
        }
      });
    },
    notify(message) {
      if (Notification.permission === "granted") {
        new Notification("新通知", {
          body: message,
        });
      }
    },
    sortAcceptedClients() {
      this.acceptedClients.sort((a, b) => {
        const timeA = new Date(a.lastMessageTime).getTime();
        const timeB = new Date(b.lastMessageTime).getTime();
        return timeB - timeA; // 时间新的在前
      });
    },
  },
  beforeUnmount() {
    if (this.connection) {
      this.connection.stop();
    }
  },
};
</script>