<template>
  <div>
    <button @click="sendCode" :disabled="countdown > 0">
      {{ countdown > 0 ? `重新发送 (${countdown}s)` : '发送验证码' }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    initialTime: {
      type: Number,
      default: 60,
    },
  },
  data() {
    return {
      countdown: 0,
    };
  },
  methods: {
    sendCode() {
      if (this.countdown === 0) {
        this.countdown = this.initialTime;
        this.$emit('send'); // 发送事件通知父组件
        this.startCountdown();
      }
    },
    startCountdown() {
      const interval = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          clearInterval(interval);
        }
      }, 1000);
    },
  },
};
</script>

<style scoped>
button {
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}
button:disabled {
  background-color: gray;
  cursor: not-allowed;
}
</style>
